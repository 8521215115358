/* Home.css */

/* Define animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 1s ease forwards;
}

.slide-in-from-right {
  animation: slideInFromRight 1s ease forwards;
}

/* Define animation for categories */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-from-bottom {
  animation: slideInFromBottom 1s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1s ease forwards;
}

@keyframes imgSlideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.img-slide-in {
  animation: imgSlideIn 1s ease forwards;
}
