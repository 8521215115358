@media (max-width: 480px) {
  .kvr-wrapper img {
    max-width: 100%;
    height: auto;
  }

  .About {
    height: auto;
    text-align: center;
  }

  .col-5,
  .col-7 {
    width: 100%;
    padding: 0;
  }

  .col-5 {
    display: none;
  }

  .col-7 {
    padding-top: 20px;
  }

  h1 {
    font-size: 28px;
    margin: 0;
  }

  .text {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    font-family: "Reddit Sans Condensed", sans-serif;
  }

  .image-wrapper {
    width: 100px;
    margin-left: 120px;
  }

  .about-image {
    display: none;
  }

  .img-hover {
    margin-left: 315px;
    margin-bottom: 100px;
  }

  .category-card {
    height: 100px;
    max-width: 200px;
  }
  .category-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease, filter 0.3s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }

  .category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
  }
  .category-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 1em;
    font-family: "Reddit Sans Condensed", sans-serif;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease, color 0.3s ease;
  }

  .category-card:hover {
    transform: scale(1.05);
  }

  .category-card:hover .category-image {
    filter: brightness(80%) blur(2px);
  }

  .category-card:hover .category-title {
    opacity: 2;
    color: white;
    font-size: 20px;
    font-weight: bold;
    font-family: "Reddit Sans Condensed", sans-serif;
  }

  .contact-details {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-details .col-4 {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .contact-details .d-flex {
    flex-direction: column;
    align-items: center;
  }

  .contact-details p {
    margin: 10px 0;
  }

  .contact {
    flex-direction: column;
    align-items: center;
    margin-left: -100px;
  }

  .contact img {
    display: none; /* Hide the image on small screens */
  }

  .contact-form {
    width: 100%; /* Ensure the form takes up the full width */
  }

  .contact-form .fields {
    flex-direction: column; /* Stack fields vertically */
    margin-bottom: 20px; /* Add space between field groups */
  }

  .contact-form .fields .form-group {
    width: 100%; /* Make each form group take the full width */
    margin-bottom: 10px; /* Add space between fields */
  }

  .contact-form input,
  .contact-form textarea {
    width: 100%; /* Ensure form fields take up the full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }

  .contact-form textarea {
    height: auto; /* Adjust textarea height if necessary */
  }

  .contact-form button {
    margin-top: 20px; 
    font-size: 16px; 
    max-width: 330px;
  }
}